import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section } from "../../components/Core";

const CTA = (props) => (
  <>
    <Container>
      <Section
        bg="ash"
        py="32px"
        px={4}
        style={{ borderRadius: 10, marginTop: -62 }}
        data-aos="flip-up"
        data-aos-duration="750"
        data-aos-once="true"
      >
        <Row>
          <Col lg="8">
            <div>
              <Title variant="card" size="40px" color="light" className="mb-0">
                {props.cta1}
              </Title>
              <Title
                variant="card"
                size="40px"
                color="lightShade"
                className="mb-0"
              >
                {props.cta2}
              </Title>
            </div>
          </Col>
          <Col
            lg="4"
            className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0"
          >
            <div>
              <a
                href={"https://" + props.url + "/manage/new"}
                target="_blank"
                rel="noopener noreferrer"
                id="clickNewTournament"
              >
                <Button bg="white" color="dark" px={["0.75rem", "1.75rem"]}>
                  <i
                    className="fas fa-trophy d-none d-md-block"
                    style={{ marginRight: 10 }}
                  />
                  {props.cta}
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Section>
    </Container>
  </>
);

export default CTA;
