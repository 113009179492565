import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { Link } from "gatsby";
import { langKeyDefault } from "../../../buildVariables";

const SectionStyled = styled(Section)`
  padding-top: 20px;
`;

const FaqCard = styled(Box)`
  padding-left: 55px;
  position: relative;
  &::before {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    font-size: 15px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.light};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    position: absolute;
    left: 0;
  }
`;

const FaqTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.light};
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -0.75px;
  margin-bottom: 24px;
  line-height: 2rem;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.lightShade} !important;
`;

const Faq = (props) => (
  <>
    {/* <!-- Faq section --> */}
    <SectionStyled bg="ash" pb="0 !important">
      <Container>
        <Row>
          {props.faqs.faq.map((faq, i) => (
            <Col lg="6" className="mb-5" key={i}>
              <FaqCard>
                <div>
                  <FaqTitle>{faq.question}</FaqTitle>
                  <Text variant="small" color="light" opacity={0.7}>
                    {faq.answer}
                  </Text>
                </div>
              </FaqCard>
            </Col>
          ))}
          <Col lg="12" className="mb-5">
            <Box className="text-center" pt={0}>
              <Text variant="small" color="light">
                {props.faqs.noanswer + " "}
                <StyledLink
                  to={
                    "/" +
                    (props.langKey === langKeyDefault
                      ? ""
                      : props.langKey + "/") +
                    "faq"
                  }
                >
                  {props.faqs.readfaq}
                </StyledLink>{" "}
                {props.faqs.or + " "}
                <StyledLink
                  to={
                    "/" +
                    (props.langKey === langKeyDefault
                      ? ""
                      : props.langKey + "/") +
                    "contact"
                  }
                >
                  {props.faqs.contactus}
                </StyledLink>
              </Text>
            </Box>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Faq;
