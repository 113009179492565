import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import { Title, Section, Box, Text, Button } from "../../components/Core";
import { device } from "../../utils";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

import iconWebsite from "../../assets/image/website.png";
import iconSlideshow from "../../assets/image/present.png";

const ContentCard = styled(Box)`
  padding: 20px;
  transition: background 1s ease;
  border-radius: 15px;

  &.not-selected:hover {
    cursor: pointer;
    background: #f7f7fb;
  }
  &.selected {
    background: #f7f7fb;
  }
`;

const ImageContainer = styled.div`
  display: none;
  filter: ${({ theme }) => `drop-shadow(0 52px 54px ${theme.colors.shadow})`};
  @media ${device.lg} {
    width: 95%;
  }
  &.selected {
    display: block;
  }
`;
const PresentationImage = styled.div`
  transition: 0.4s;
  @media ${device.lg} {
    ${ImageContainer}:hover & {
      filter: brightness(80%);
    }
  }
`;

const BtnContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin-top: 7px;
  opacity: 0;
  transition: 0.4s opacity, 0.4s margin-top;
  height: 40px;
  font-size: 16px;
  text-align: center;

  @media ${device.lg} {
    ${ImageContainer}:hover & {
      opacity: 1;
      margin-top: -13px;
    }
    button {
      font-size: 16px;
      padding: 0.85rem 1.75rem;
    }
  }
`;

class Presentation extends React.Component {
  state = {
    url: "",
    screenWidth: 0,
    selectedUSP: 0,
  };

  setSelectedUSP = (usp) => {
    this.setState({ selectedUSP: usp });
  };

  componentDidMount() {
    const screenWidth = window.innerWidth;
    this.setState({
      url: window.location.hostname,
      screenWidth: screenWidth,
      selectedUSP: screenWidth > 991 ? 1 : 0,
    });
  }

  render() {
    const { url, screenWidth, selectedUSP } = this.state;

    return (
      <PresentationElement
        {...this.props}
        url={url}
        screenWidth={screenWidth}
        selectedUSP={selectedUSP}
        setSelectedUSP={this.setSelectedUSP}
      />
    );
  }
}

const PresentationElement = (props) => {
  return (
    <>
      {/* <!-- Content section 2 --> */}
      <Section>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="order-lg-2">
              <Box
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <Title>
                  {props.presentation.title}
                  {/* <br className="d-none d-sm-block d-md-none d-xl-block" /> */}
                </Title>
                <Text>{props.presentation.description}</Text>
                <Box mt={3} pt={3}>
                  <ContentCard
                    className={
                      "d-flex align-items-start justify-content-start " +
                      (0 === props.selectedUSP ? "selected" : "not-selected")
                    }
                    onClick={() => props.setSelectedUSP(0)}
                    mb={4}
                  >
                    <Box mr={4}>
                      <img src={iconWebsite} alt="" />
                    </Box>
                    <Box>
                      <Title variant="card" fontSize="21px">
                        {props.presentation.feature1title}
                      </Title>
                      <Text variant="small">
                        {props.presentation.feature1description}
                      </Text>
                    </Box>
                  </ContentCard>

                  <ContentCard
                    className={
                      "d-flex align-items-start justify-content-start " +
                      (1 === props.selectedUSP ? "selected" : "not-selected")
                    }
                    onClick={() => props.setSelectedUSP(1)}
                  >
                    <Box mr={4}>
                      <img src={iconSlideshow} alt="" />
                    </Box>
                    <Box>
                      <Title variant="card" fontSize="21px">
                        {props.presentation.feature2title}
                      </Title>
                      <Text variant="small">
                        {props.presentation.feature2description}
                      </Text>
                    </Box>
                  </ContentCard>
                </Box>
              </Box>
            </Col>

            <Col lg="6" className="order-lg-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="200"
                data-aos-once="true"
                className="mt-4 mt-lg-0"
              >
                <ImageContainer
                  className={0 === props.selectedUSP ? "selected" : ""}
                >
                  <PresentationImage>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: props.presentation.image,
                      }}
                    />
                  </PresentationImage>
                  <BtnContainer className="d-none d-lg-block">
                    <a
                      href={"https://" + props.url + "/live/demo"}
                      target="_blank"
                      rel="noopener noreferrer"
                      id="OpenDemoWebsite"
                    >
                      <Button>
                        <i
                          className="fas fa-external-link-alt"
                          style={{ marginRight: 10 }}
                        />
                        {props.presentation.demo_website}
                      </Button>
                    </a>
                  </BtnContainer>
                  <a
                    href={"https://" + props.url + "/live/demo"}
                    target="_blank"
                    rel="noopener noreferrer"
                    id="OpenDemoWebsite"
                  >
                    <Button
                      variant="outline"
                      color="dark"
                      className="d-block d-lg-none d-flex mx-auto mt-4"
                    >
                      <i
                        className="fas fa-external-link-alt"
                        style={{ marginRight: 10 }}
                      />
                      {props.presentation.demo_website}
                    </Button>
                  </a>
                </ImageContainer>

                <ImageContainer
                  className={1 === props.selectedUSP ? "selected" : ""}
                >
                  <PresentationImage>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: props.presentation.image2,
                      }}
                    />
                  </PresentationImage>
                  <BtnContainer className="d-none d-lg-block">
                    <a
                      href={"https://" + props.url + "/live/demo/present"}
                      target="_blank"
                      rel="noopener noreferrer"
                      id="OpenDemoWebsite"
                    >
                      <Button>
                        <i
                          className="fas fa-external-link-alt"
                          style={{ marginRight: 10 }}
                        />
                        {props.presentation.demo_slideshow}
                      </Button>
                    </a>
                  </BtnContainer>
                </ImageContainer>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Presentation;
