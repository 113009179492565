import React from "react";
import PropTypes from "prop-types";
import PageWrapper from "../components/PageWrapper";

import Hero from "../sections/landing/Hero2";
import Testimonial from "../sections/landing/Testimonial";
import Pricing from "../sections/landing/Pricing";
import Faq from "../sections/landing/Faq";
import Content3 from "../sections/landing/Content3";
import CTA from "../sections/landing/CTA";
import Presentation from "../sections/landing/Presentation";
import Clients from "../sections/landing/Clients";
import BlogSection from "../sections/landing/BlogSection";
import { Section } from "../components/Core";

export const IndexPageTemplate = ({
  image,
  frontImage,
  title,
  heading,
  heading2,
  heading3,
  sports,
  reverse,
  subheading,
  subheading2,
  subheading3,
  cta,
  mainpitch,
  presentation,
  intro,
  faqpricing,
  blog,
  blogs,
  pricing,
  location,
  langKey,
  url,
  multiCurrency,
}) => (
  <div>
    <Hero
      image={image}
      frontImage={frontImage}
      title={title}
      heading={heading}
      heading2={heading2}
      heading3={heading3}
      sports={sports}
      reverse={reverse}
      subheading={subheading}
      subheading2={subheading2}
      subheading3={subheading3}
      cta={cta}
      url={url}
      location={location}
    />
    <Content3
      mainpitch={mainpitch.title}
      description={mainpitch.description}
      usps={mainpitch.usps}
    />
    <Clients
      heading={mainpitch.clientsheading}
      clients={mainpitch.clients}
      bg="#F7F7FB"
      pt="80px"
      pb="80px"
    />
    <Section
      bg="#F7F7FB"
      pt="0px !important"
      pb={[
        "40px !important",
        "40px !important",
        "80px !important",
        "90px !important",
      ]}
    />
    <CTA url={url} cta={cta} cta1={mainpitch.cta1} cta2={mainpitch.cta2} />
    <Presentation presentation={presentation} />
    <Testimonial
      heading={intro.heading}
      subheading={intro.description}
      quotes={intro.blurbs}
    />
    <Pricing
      dark
      data={pricing}
      langKey={langKey}
      multiCurrency={multiCurrency}
    />
    <Faq faqs={faqpricing} langKey={langKey} />
    <BlogSection
      heading={blog.heading}
      subheading={blog.subheading}
      cta={blog.cta}
      readmore={blog.readmore}
      blogs={blogs}
      langKey={langKey}
    />
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data, location, url, multiCurrency }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <PageWrapper footerDark changeOnScroll>
      <IndexPageTemplate
        image={frontmatter.image}
        frontImage={frontmatter.frontImage}
        title={frontmatter.title}
        heading={frontmatter.heading}
        heading2={frontmatter.heading2}
        heading3={frontmatter.heading3}
        sports={frontmatter.sports}
        reverse={frontmatter.reverse}
        subheading={frontmatter.subheading}
        subheading2={frontmatter.subheading2}
        subheading3={frontmatter.subheading3}
        cta={frontmatter.cta}
        mainpitch={frontmatter.mainpitch}
        presentation={frontmatter.presentation}
        description={frontmatter.description}
        intro={frontmatter.intro}
        faqpricing={frontmatter.faqpricing}
        blog={frontmatter.blog}
        blogs={data.allMarkdownRemark}
        pricing={data.pricing}
        location={location}
        langKey={data.markdownRemark.fields.langKey}
        url={url}
        multiCurrency={multiCurrency}
      />
    </PageWrapper>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

class IndexPageWrapper extends React.Component {
  state = {
    url: "",
  };

  componentDidMount() {
    this.setState({ url: window.location.hostname });
  }

  render() {
    const { url } = this.state;

    return <IndexPage {...this.props} url={url} />;
  }
}

export default IndexPageWrapper;
